/**
 * NextJS optional catch-all dynamic route.
 *
 * Ref: https://nextjs.org/docs/routing/dynamic-routes#optional-catch-all-routes
 *
 */
import { NextPage } from 'next';
import { getSiteData } from '@Lib/data-access/private';

export const getStaticProps = getSiteData;

const Error404Page: NextPage = () => {
  return (
    <p>
      The page you were looking for could not be found. It may have been
      deleted, renamed, or does not exist.
    </p>
  );
};

export default Error404Page;
